
import { createRouter, createWebHashHistory } from 'vue-router';

import { useAuth } from 'src/composables/auth';


function passRouteToProps(route){
	return {
		queryParams: route.query,
		fieldName: route.params.fieldName, 
		fieldValue: route.params.fieldValue
	}
}


let routes = [
	//Dashboard routes


//comments routes
			{
				path: '/comments/:fieldName?/:fieldValue?',
				name: 'commentslist',
				component: () => import('./pages/comments/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/comments/view/:id', 
				name: 'commentsview', 
				component: () => import('./pages/comments/view.vue'), 
				props: true
			},
		
			{ 
				path: '/comments/add', 
				name: 'commentsadd', 
				component: () => import('./pages/comments/add.vue'), 
				props: true
			},
	
			{ 
				path: '/comments/edit/:id', 
				name: 'commentsedit', 
				component: () => import('./pages/comments/edit.vue'), 
				props: true
			},
		

//customers routes
			{
				path: '/customers/:fieldName?/:fieldValue?',
				name: 'customerslist',
				component: () => import('./pages/customers/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/customers/view/:id', 
				name: 'customersview', 
				component: () => import('./pages/customers/view.vue'), 
				props: true
			},
		
			{ 
				path: '/customers/add', 
				name: 'customersadd', 
				component: () => import('./pages/customers/add.vue'), 
				props: true
			},
	
			{ 
				path: '/customers/edit/:id', 
				name: 'customersedit', 
				component: () => import('./pages/customers/edit.vue'), 
				props: true
			},
		

//detaildescription routes
			{
				path: '/detaildescription/:fieldName?/:fieldValue?',
				name: 'detaildescriptionlist',
				component: () => import('./pages/detaildescription/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/detaildescription/view/:id', 
				name: 'detaildescriptionview', 
				component: () => import('./pages/detaildescription/view.vue'), 
				props: true
			},
		
			{ 
				path: '/detaildescription/add', 
				name: 'detaildescriptionadd', 
				component: () => import('./pages/detaildescription/add.vue'), 
				props: true
			},
	
			{ 
				path: '/detaildescription/edit/:id', 
				name: 'detaildescriptionedit', 
				component: () => import('./pages/detaildescription/edit.vue'), 
				props: true
			},
		

//permissions routes
			{
				path: '/permissions/:fieldName?/:fieldValue?',
				name: 'permissionslist',
				component: () => import('./pages/permissions/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/permissions/view/:id', 
				name: 'permissionsview', 
				component: () => import('./pages/permissions/view.vue'), 
				props: true
			},
		
			{ 
				path: '/permissions/add', 
				name: 'permissionsadd', 
				component: () => import('./pages/permissions/add.vue'), 
				props: true
			},
	
			{ 
				path: '/permissions/edit/:id', 
				name: 'permissionsedit', 
				component: () => import('./pages/permissions/edit.vue'), 
				props: true
			},
		

//roles routes
			{
				path: '/roles/:fieldName?/:fieldValue?',
				name: 'roleslist',
				component: () => import('./pages/roles/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/roles/view/:id', 
				name: 'rolesview', 
				component: () => import('./pages/roles/view.vue'), 
				props: true
			},
		
			{ 
				path: '/roles/add', 
				name: 'rolesadd', 
				component: () => import('./pages/roles/add.vue'), 
				props: true
			},
	
			{ 
				path: '/roles/edit/:id', 
				name: 'rolesedit', 
				component: () => import('./pages/roles/edit.vue'), 
				props: true
			},
		

//status routes
			{
				path: '/status/:fieldName?/:fieldValue?',
				name: 'statuslist',
				component: () => import('./pages/status/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/status/view/:id', 
				name: 'statusview', 
				component: () => import('./pages/status/view.vue'), 
				props: true
			},
		
			{ 
				path: '/status/add', 
				name: 'statusadd', 
				component: () => import('./pages/status/add.vue'), 
				props: true
			},
	
			{ 
				path: '/status/edit/:id', 
				name: 'statusedit', 
				component: () => import('./pages/status/edit.vue'), 
				props: true
			},
		

//user routes
			{
				path: '/user/:fieldName?/:fieldValue?',
				name: 'userlist',
				component: () => import('./pages/user/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/user/view/:id', 
				name: 'userview', 
				component: () => import('./pages/user/view.vue'), 
				props: true
			},
		
			{ 
				path: '/account/edit', 
				name: 'useraccountedit', 
				component: () => import('./pages/account/accountedit.vue'), 
				props: true
			},
	
			{ 
				path: '/account', 
				name: 'useraccountview', 
				component: () => import('./pages/account/accountview.vue'), 
				props: true
			},
	
			{ 
				path: '/user/add', 
				name: 'useradd', 
				component: () => import('./pages/user/add.vue'), 
				props: true
			},
	
			{ 
				path: '/user/edit/:id', 
				name: 'useredit', 
				component: () => import('./pages/user/edit.vue'), 
				props: true
			},
		

//workdescription routes
			{
				path: '/workdescription/:fieldName?/:fieldValue?',
				name: 'workdescriptionlist',
				component: () => import('./pages/workdescription/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/workdescription/view/:id', 
				name: 'workdescriptionview', 
				component: () => import('./pages/workdescription/view.vue'), 
				props: true
			},
		
			{ 
				path: '/workdescription/add', 
				name: 'workdescriptionadd', 
				component: () => import('./pages/workdescription/add.vue'), 
				props: true
			},
	
			{ 
				path: '/workdescription/edit/:id', 
				name: 'workdescriptionedit', 
				component: () => import('./pages/workdescription/edit.vue'), 
				props: true
			},
		

//worklocation routes
			{
				path: '/worklocation/:fieldName?/:fieldValue?',
				name: 'worklocationlist',
				component: () => import('./pages/worklocation/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/worklocation/view/:id', 
				name: 'worklocationview', 
				component: () => import('./pages/worklocation/view.vue'), 
				props: true
			},
		
			{ 
				path: '/worklocation/add', 
				name: 'worklocationadd', 
				component: () => import('./pages/worklocation/add.vue'), 
				props: true
			},
	
			{ 
				path: '/worklocation/edit/:id', 
				name: 'worklocationedit', 
				component: () => import('./pages/worklocation/edit.vue'), 
				props: true
			},
		

//workorders routes
			{
				path: '/workorders/:fieldName?/:fieldValue?',
				name: 'workorderslist',
				component: () => import('./pages/workorders/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/workorders/view/:id', 
				name: 'workordersview', 
				component: () => import('./pages/workorders/view.vue'), 
				props: true
			},
		
			{ 
				path: '/workorders/add', 
				name: 'workordersadd', 
				component: () => import('./pages/workorders/add.vue'), 
				props: true
			},
	
			{ 
				path: '/workorders/edit/:id', 
				name: 'workordersedit', 
				component: () => import('./pages/workorders/edit.vue'), 
				props: true
			},
		

	
	
	
	
	{ 
		path:  '/error/forbidden', 
		name: 'forbidden', 
		component: () => import('./pages/errors/forbidden.vue'),
		props: true
	},
	{ 
		path: '/error/notfound', 
		name: 'notfound',
		component: () => import('./pages/errors/pagenotfound.vue'),
		props: true
	},
	{
		path: '/:catchAll(.*)', 
		component: () => import('./pages/errors/pagenotfound.vue')
	}
];

export default () => {
	
const auth = useAuth();

	
	const user = auth.user;
	if(user){
		routes.push({ path: '/', alias: '/home', name: 'home', component: () => import(`./pages/home/home.vue`) });
	}
	else{
		routes.push({ path: '/', alias: '/index', name: 'index', component: () => import('./pages/index/index.vue') });
	}

	const router = createRouter({
		history: createWebHashHistory(),
		routes,
		scrollBehavior(to, from, savedPostion){
			if(savedPostion) return savedPostion;
			return { top:0 }
		}
	});
	
	router.beforeEach((to, from, next) => {
		const user = auth.user;
		let path = to.path;
		let authRequired = auth.pageRequiredAuth(path);
		if (authRequired) {
			if(!user){
				return next({ path: '/',  query: { nexturl: to.fullPath } });
			}
			//authorize user
			if (!auth.canView(path)) {
				return next({path: "/error/forbidden"});
			}
		}

		if(user && to.name == "index"){
			//already logged in, show home when try to access index page
			return next({ path: "/home"});
		}

		//navigate to redirect url if available
		if(to.name == "home" && to.query.nexturl){
			return next({ path: to.query.nexturl});
		}

 		//close dialog from previous page
		//store.closeDialogs('app/closeDialogs');
		
		next();
	});

	return router;
}
