
export const AppMenus = {
    
	navbarTopRight: [],
	navbarTopLeft: [],
	navbarSideLeft: [
  {
    "to": "/home",
    "label": "Home",
    "icon": "pi pi-home",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/workorders",
    "label": "Workorders",
    "icon": "pi pi-wrench",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/customers",
    "label": "Customers",
    "icon": "pi pi-users",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/comments",
    "label": "Comments",
    "icon": "pi pi-comments",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/detaildescription",
    "label": "Detaildescription",
    "icon": "pi pi-align-left",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/permissions",
    "label": "Permissions",
    "icon": "pi pi-check-circle",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/roles",
    "label": "Roles",
    "icon": "pi pi-hourglass",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/status",
    "label": "Status",
    "icon": "pi pi-sliders-v",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/user",
    "label": "User",
    "icon": "pi pi-user",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/workdescription",
    "label": "Workdescription",
    "icon": "pi pi-upload",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/worklocation",
    "label": "Worklocation",
    "icon": "pi pi-send",
    "iconcolor": "",
    "target": "",
    
  }
],

    exportFormats: {
        print: {
			label: 'Print',
            icon: 'pi pi-print',
            type: 'print',
            ext: 'print',
        },
        pdf: {
			label: 'Pdf',
			
            icon: 'pi pi-file-pdf',
            type: 'pdf',
            ext: 'pdf',
        },
        excel: {
			label: 'Excel',
            icon: 'pi pi-file-excel',
            type: 'excel',
            ext: 'xlsx',
        },
        csv: {
			label: 'Csv',
            icon: 'pi pi-table',
            type: 'csv',
            ext: 'csv',
        },
    },
    
}